import React from 'react';
import {
  Layout,
  Module,
  Hero,
  HalfAndHalf,
  TextLockup,
  Text,
  CTA,
  SingleQuote,
  ThirdPartyQuoteCarousel,
  StandardCarousel
} from '../redesign';
import heroContent from '../redesign/content/company/company-hero.json';
import quoteContent from '../redesign/content/company/quote.json';
import ourStory from '../redesign/content/company/our-story.json';
import membersData from '../redesign/content/company/board-members.json';
import halfAndHalfContent from '../redesign/content/company/company-half-n-half.json';
import { default as SEO } from '../components/SEO';

export default function Company() {
  return (
    <Layout footerContentKey="company">
      <SEO
        pageTitle="About the Company| Parsley Health"
        pageDescription="About Parsley Health"
      />
      <Module>
        <Hero {...heroContent} hideHeroImageOnMobile />
      </Module>
      <Module>
        <HalfAndHalf
          image={halfAndHalfContent.image}
          imagePosition={halfAndHalfContent.imagePosition}
          fullImage
        >
          <TextLockup alignment={['center', 'left']}>
            <Text variant="h2a" className="mb-sm">
              {halfAndHalfContent.headline}
            </Text>
            <Text variant="p2a">{halfAndHalfContent.description}</Text>
            <CTA
              className="mt-sm"
              color="sage"
              variant="filled"
              {...halfAndHalfContent.cta}
            />
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module>
        <SingleQuote {...quoteContent} />
      </Module>
      <Module>
        <HalfAndHalf
          image={ourStory.image}
          bgColor="gray-nurse"
          paddingY={false}
          contentClassNames="px-lg"
          reversed={ourStory.reversed}
          imageOffsetValue={[1, 0]}
          contentOffsetValue={1}
          inset
          fullImage
        >
          <TextLockup alignment="center">
            {ourStory.eyebrow && (
              <Text variant="p3b" className="hero-eyebrow mb-xs">
                {ourStory.eyebrow}
              </Text>
            )}
            <Text variant="h1a" className="hero-headline mb-xs">
              {ourStory.headline}
            </Text>
            {ourStory.description && (
              <Text variant="p2a" className="hero-description mb-xs">
                {ourStory.description}
              </Text>
            )}
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module animate>
        <StandardCarousel
          type="advisoryBoard"
          members={membersData.slides}
          heading={membersData.heading}
        />
      </Module>
      <Module>
        <ThirdPartyQuoteCarousel reversed={true} />
      </Module>
    </Layout>
  );
}
